import create from 'zustand'

const usePopupStore = create((set, get) => ({
    popupComponent: null,
    scrollable: true,
    
    setPopupComponent: async (popupComponent, scrollable=true) => {
        set({'popupComponent': popupComponent, scrollable});
    },
    setScrollable: async (scrollable) => {
        set({scrollable});
    },
}))

export default usePopupStore