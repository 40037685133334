import {PageTheme, mediaMax} from "./Theme"
import styled from "styled-components";
import { useEffect } from "react";
import { setDocumentTitle } from "./App";

const Video = styled.video`
  display: block;

  margin-left: auto;
  margin-right: auto;

  max-width: 100%;
`    

export default function Home() {
  useEffect(() => setDocumentTitle(''), [])

  return (
    <Video loop autoPlay muted>
      <source src="trackgalleryHowTo.webm" />
      <p>Your browser doesn't support HTML video.</p>
    </Video>
  );
}