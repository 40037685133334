const {hostname} = window.location;

export const ENVIRONMENT_LOCAL = 'ENVIRONMENT_LOCAL';
export const ENVIRONMENT_REVIEW = 'ENVIRONMENT_REVIEW';
export const ENVIRONMENT_STAGING = 'ENVIRONMENT_STAGING';
export const ENVIRONMENT_PRODUCTION = 'ENVIRONMENT_PRODUCTION';
export const ENVIRONMENT_UNKNOWN = 'ENVIRONMENT_UNKNOWN';

export const environment = (function(){
    if(hostname === 'localhost')
        return ENVIRONMENT_LOCAL;

    if(hostname === 'trga.uber.space' || hostname === 'www.trga.uber.space' || hostname === 'track.gallery')
        return ENVIRONMENT_PRODUCTION;

    if(hostname === 'staging.trga.uber.space' || hostname === 'www.staging.trga.uber.space')
        return ENVIRONMENT_STAGING;

    if(hostname.endsWith('.trga.uber.space'))
        return ENVIRONMENT_REVIEW;

    return ENVIRONMENT_UNKNOWN;
})();

const backend = (function(){
    switch (environment) {
        case ENVIRONMENT_PRODUCTION:
            return `https://backend.trga.uber.space`
 
        case ENVIRONMENT_REVIEW:
        case ENVIRONMENT_STAGING:
            return `https://staging.backend.trga.uber.space`
           
        case ENVIRONMENT_LOCAL:
        case ENVIRONMENT_UNKNOWN:
        default:
            return `http://${hostname}:1337`
    }
})();

export const apiBaseUrl = `${backend}/`;
