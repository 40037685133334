
import React, { useCallback, useEffect } from "react";
import styled, {css} from 'styled-components'
import {
    BrowserRouter as Router,
    Switch,
    Route,
    NavLink,
    Redirect
  } from "react-router-dom";
import useSessionStore from "./store/session/index"
import IconUnstyled from "./Icons.js"
import Login from './Login.js'
import Upload from './Upload.js'
import Profile from "./Profile";
import TrackLoader from "./TrackLoader";
import PlaylistLoader from "./PlaylistLoader";
import About from "./About";
import Home from "./Home";
import Registration from "./Registration";
import GlobalPlayer from './Player/Player';
import usePlayerStore from "./store/player/usePlayer";
import LoadingSpinner from "./LoadingSpinner";
import Rules from "./Rules";
import { PageTheme } from "./Theme";
import {mediaMax , mediaMin} from "./Theme.js"
import Indicator from "./Indicator";
import useNotificationStore from "./store/notification";
import ConfirmEmail from "./ConfirmEmail";

const Icon = styled(IconUnstyled)`
    fill: inherit;
    width: ${ PageTheme.properties.size5 };
    
    margin-left: auto;
    margin-right: auto;
    display: block;
`

const NavLinkText = styled.span`
    position: absolute;
    top: 4px;
    left: 50px;
    width: 200px;
    text-align: left;
    background-color: #00000040;
    border-radius: ${ PageTheme.properties.size2 };
    padding: 10px;
    z-index: 999;
    display: none;
    margin-left: 7px;
    pointer-events: none
`
const navLinkHover = css`
    &:hover {
        background-color: #FFFFFF10;
    }

    ${mediaMin('large', css`
        &:hover ${NavLinkText} {
            display: initial;
        }
    `)}


    &.active {
        fill: #FFF;
    }
`

const IndicatorContainer = styled.div`
    position: absolute;
    left: calc(50% + 4px);
    bottom: calc(50% + 4px);
    transform: scale(0.75);
`
const StyledNavLink = styled(NavLink)`
    color: #FFF;
    fill: #FFFFFF87;
    text-decoration: none;
    display: flex;
    width: 48px;
    height: 48px;
    margin-left: auto;
    margin-right: auto;
    border-radius: ${ PageTheme.properties.size3 };
    text-align: center;
    position: relative;
    outline: 0;

    ${navLinkHover}

    &.active {
        ${IndicatorContainer} {
            display: none;
        }
    }

`

const Nav = styled.nav`
  position: absolute;
  top: 0;
  left: 0;
  top: 28px;
  left: 40px;
  width: 48px;
  ${mediaMax('small', css`
      left: auto;
      right 8px;
      top: 10px;
      z-index: 1;
  `)}
`

const Page = styled.div`
    position: absolute;
    top: 0;
    left: 128px;
    right 32px;
    bottom: 0;
    display: flex;
    flex-direction: column;
	${mediaMax('small', css`
        left: 8px;
        right 8px;
	`)}
`
const ContentScrollContainer = styled.div`
    overflow: auto;
    flex-grow: 1;
`

const Ul = styled.ul`
    list-style-type: none;
    margin: 0;
    padding: 0;
`

function NavItem({to, showText, children}) {
    return (
        <li>
            <StyledNavLink to={to} activeClassName="active" exact>
                {children}
                <NavLinkText>{showText}</NavLinkText>
            </StyledNavLink>
        </li>
    )
}

export default function Navigation() {
    const {user, unconfirmedUser} = useSessionStore(state => state);
	const isReconnecting = useSessionStore(state => state.isReconnecting);
	const notifications = useNotificationStore(state => state.notifications);
    const {file,attached} = usePlayerStore();
    
    const globalPlayerVisible = file && !attached;

    const authenticated = useCallback((component) => {
        if( isReconnecting) return <LoadingSpinner />         
        if(user) return component;
        if(unconfirmedUser) return <ConfirmEmail />
        return <Redirect to="/login" />
    }, [isReconnecting, user, unconfirmedUser]);

    const publik = useCallback((component) => {
        if(unconfirmedUser) return <ConfirmEmail />;
        if(user) return <Redirect to="/profile" />;
        return component;
    }, [user, unconfirmedUser])

    return (
        <Router>
            <Nav>
                <Ul>
                    { user
                     ? <NavItem to="/profile" showText="Profile">
                        <Icon id="PersonBackground" />
                        {notifications.length !== 0 && <IndicatorContainer>
                                <Indicator>{notifications.length}</Indicator>
                            </IndicatorContainer>}
                        </NavItem> 
                     : <NavItem to="/login" showText="Login"><Icon id="Person" /></NavItem> }
                    <NavItem to="/about" showText="About track.gallery"><Icon id="Info" /></NavItem>
                </Ul>
            </Nav>

            <Page>
                <ContentScrollContainer>
                    <Switch>
                        {!isReconnecting && [
                            <Route path="/" exact>
                                <Home />
                            </Route>,
                            <Route path="/login">
                                {publik(<Login />)}
                            </Route>
                        ].map((route,i) => ({...route, key: i}))}
                        <Route path="/track/upload">
                            {authenticated(<Upload />)}
                        </Route>,
                        <Route path="/track/:uid" component={TrackLoader}/>
                        <Route path="/playlist/:uid/:id?" component={PlaylistLoader}/>
                        <Route path="/profile">
                            {authenticated(<Profile />)}
                        </Route>
                        <Route path="/signup">
                            {publik(<Registration />)}
                        </Route>
                        <Route path="/about">
                            <About />
                        </Route>
                        <Route path="/rules">
                            <Rules />
                        </Route>
                        {!isReconnecting && <Route path="/"><div>Not found</div></Route>}
                    </Switch>
                </ContentScrollContainer>
                <GlobalPlayer visible={globalPlayerVisible} />
            </Page>
            
        </Router>
    );
}
